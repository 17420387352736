var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"h-full"},[_c('div',{staticClass:"other-content app-other-content",class:[
			{ 'show-overlay': _vm.$store.state.app.shallShowOverlay },
			_vm.$route.meta.contentClass,
			_vm.$route.meta.pageTitle !== 'Chat'
				? _vm.$route.meta.kind === 'withoutSidebarLayout'
					? 'other-content app-other-content full-height-other-content'
					: 'app-content content'
				: 'chat-content other-content app-other-content full-height-other-content' ]},[_c('div',{staticClass:"content-overlay"}),_c('div',{staticClass:"content-wrapper h-full",class:_vm.contentWidth === 'boxed' ? 'container p-0' : null},[_c('div',{staticClass:"content-body h-full"},[_c('transition',{attrs:{"name":_vm.routerTransition,"mode":"out-in"}},[_vm._t("default")],2)],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }