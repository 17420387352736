<template>
	<div class="h-full">
		<div
			class="other-content app-other-content"
			:class="[
				{ 'show-overlay': $store.state.app.shallShowOverlay },
				$route.meta.contentClass,
				$route.meta.pageTitle !== 'Chat'
					? $route.meta.kind === 'withoutSidebarLayout'
						? 'other-content app-other-content full-height-other-content'
						: 'app-content content'
					: 'chat-content other-content app-other-content full-height-other-content',
			]"
		>
			<div class="content-overlay" />

			<div class="content-wrapper h-full" :class="contentWidth === 'boxed' ? 'container p-0' : null">
				<div class="content-body h-full">
					<transition :name="routerTransition" mode="out-in">
						<slot />
					</transition>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import useAppConfig from "@core/app-config/useAppConfig";

	export default {
		components: {},
		setup() {
			const { routerTransition, contentWidth } = useAppConfig();

			return {
				routerTransition,
				contentWidth,
			};
		},
	};
</script>

<style></style>
