<template>
	<div
		class="main-menu menu-fixed menu-accordion"
		:class="[
			{ expanded: !isVerticalMenuCollapsed || (isVerticalMenuCollapsed && isMouseHovered) },
			skin === 'semi-dark' ? 'menu-dark' : 'menu-light',
		]"
		@mouseenter="updateMouseHovered(true)"
		@mouseleave="updateMouseHovered(false)"
	>
		<!-- Shadow -->
		<div :class="{ 'd-block': shallShadowBottom }" class="shadow-bottom" />

		<!-- main menu content-->
		<vue-perfect-scrollbar
			:settings="perfectScrollbarSettings"
			class="main-menu-content scroll-area"
			tagname="ul"
			@ps-scroll-y="
				evt => {
					shallShadowBottom = evt.srcElement.scrollTop > 0;
				}
			"
		>
			<vertical-nav-menu-items :items="parsedNavMenuItems" class="navigation navigation-main" />
		</vue-perfect-scrollbar>
		<!-- /main menu content-->
	</div>
</template>

<script>
	import navMenuItems from "@/navigation";
	import VuePerfectScrollbar from "vue-perfect-scrollbar";
	import { BLink, BImg } from "bootstrap-vue";
	import { provide, computed, ref } from "@vue/composition-api";
	import useAppConfig from "@core/app-config/useAppConfig";
	import { $themeConfig } from "@themeConfig";
	import VerticalNavMenuItems from "./components/vertical-nav-menu-items/VerticalNavMenuItems.vue";
	import useVerticalNavMenu from "./useVerticalNavMenu";
	import store from "@/store";

	export default {
		components: {
			VuePerfectScrollbar,
			VerticalNavMenuItems,
			BLink,
			BImg,
		},
		props: {
			isVerticalMenuActive: {
				type: Boolean,
				required: true,
			},
			toggleVerticalMenuActive: {
				type: Function,
				required: true,
			},
		},
		computed: {
			parsedNavMenuItems() {
				return navMenuItems.filter(navItem => {
					if (!navItem.roles) {
						return true;
					}

					return navItem.roles.includes(this.userData?.role);
				});
			},
		},
		setup(props) {
			const { isMouseHovered, isVerticalMenuCollapsed, collapseTogglerIcon, toggleCollapsed, updateMouseHovered } =
				useVerticalNavMenu(props);

			const { skin } = useAppConfig();

			// Shadow bottom is UI specific and can be removed by user => It's not in `useVerticalNavMenu`
			const shallShadowBottom = ref(false);

			provide("isMouseHovered", isMouseHovered);

			const perfectScrollbarSettings = {
				maxScrollbarLength: 60,
				wheelPropagation: false,
			};

			const collapseTogglerIconFeather = computed(() =>
				collapseTogglerIcon.value === "unpinned" ? "CircleIcon" : "DiscIcon",
			);

			// App Name
			const { appName } = $themeConfig.app;

			return {
				navMenuItems,
				perfectScrollbarSettings,
				isVerticalMenuCollapsed,
				collapseTogglerIcon,
				toggleCollapsed,
				isMouseHovered,
				updateMouseHovered,
				collapseTogglerIconFeather,

				// Shadow Bottom
				shallShadowBottom,

				// Skin
				skin,

				// App Name
				appName,
			};
		},
		data() {
			return {
				userData: this.$store.state.auth.activeUser,
			};
		},
	};
</script>

<style lang="scss">
	@import "~@core/scss/base/core/menu/menu-types/vertical-menu.scss";
</style>
