<template>
	<li
		v-if="canViewVerticalNavMenuLink(item)"
		class="nav-item"
		:class="[
			{
				active: isActive,
				disabled: item.disabled,
			},
			isChild ? 'pl-2' : 'pl-10',
		]"
	>
		<b-link v-bind="linkProps" class="d-flex align-items-center">
			<span
				class="menu-item text-truncate font-bold"
				:class="[!isChild && 'mt-6', isChild && isActive ? 'text-journey-light-gray' : 'text-journey-gray']"
				>{{ t(item.title) }}</span
			>
			<b-badge v-if="item.tag" pill :variant="item.tagVariant || 'primary'" class="mr-1 ml-auto">
				{{ item.tag }}
			</b-badge>
		</b-link>
	</li>
</template>

<script>
	import { useUtils as useAclUtils } from "@core/libs/acl";
	import { BLink, BBadge } from "bootstrap-vue";
	import { useUtils as useI18nUtils } from "@core/libs/i18n";
	import useVerticalNavMenuLink from "./useVerticalNavMenuLink";
	import mixinVerticalNavMenuLink from "./mixinVerticalNavMenuLink";

	export default {
		components: {
			BLink,
			BBadge,
		},
		mixins: [mixinVerticalNavMenuLink],
		props: {
			item: {
				type: Object,
				required: true,
			},
			isChild: {
				type: Boolean,
				required: false,
				default: false,
			},
		},
		setup(props) {
			const { isActive, linkProps, updateIsActive } = useVerticalNavMenuLink(props.item);
			const { t } = useI18nUtils();
			const { canViewVerticalNavMenuLink } = useAclUtils();

			return {
				isActive,
				linkProps,
				updateIsActive,

				// ACL
				canViewVerticalNavMenuLink,

				// i18n
				t,
			};
		},
	};
</script>
