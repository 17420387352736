export default [
	{
		title: "Dashboard",
		route: "dashboard",
		roles: ["ADMINISTRATOR"],
	},
	{
		roles: ["ADMINISTRATOR"],
		title: "Empresas",
		route: "empresas",
	},
	{
		roles: ["ADMINISTRATOR"],
		title: "Clientes corporativos",
		route: "clientes-corporativos",
	},
	{
		roles: ["ADMINISTRATOR"],
		title: "Clientes",
		route: "clientes",
	},
	{
		roles: ["ADMINISTRATOR"],
		title: "Mensagens",
		route: "mensagens",
	},
	{
		roles: ["ADMINISTRATOR"],
		title: "Tags",
		route: "tags",
	},
	{
		roles: ["ADMINISTRATOR"],
		title: "Necessidades",
		route: "necessidades",
	},
	{
		roles: ["ADMINISTRATOR", "GUARDIANSHIP"],
		title: "Soluções",
		route: "solucoes",
	},
	{
		roles: ["ADMINISTRATOR"],
		title: "Mailing",
		route: "campanhas",
	},
	{
		roles: ["ADMINISTRATOR"],
		title: "Usuários",
		route: "usuarios",
	},
	{
		roles: ["ADMINISTRATOR"],
		title: "Agenda",
		route: null,
		children: [
			{
				route: "agendamentos",
				title: "Agendamentos",
			},
			{
				route: "disponibilidades",
				title: "Disponibilidades",
			},
		],
	},
	{
		roles: ["ADMINISTRATOR"],
		title: "Acompanhamento",
		route: null,
		children: [
			{
				route: "relatorio-agendamentos",
				title: "Agendamentos",
			},
			{
				route: "relatorio-conversas",
				title: "Conversas",
			},
		],
	},
];
