<template>
	<div class="navbar-container d-flex align-items-center content">
		<!-- Nav Menu Toggler -->
		<b-navbar-brand @click="toggleVerticalMenuActive" class="ml-14">
			<img src="../../assets/icons/Journey.svg" alt="Logotipo Journey" />
		</b-navbar-brand>

		<!-- Left Col -->
		<b-navbar-nav class="nav align-items-center ml-auto mr-14">
			<b-nav-item-dropdown right>
				<template #button-content>
					<div class="flex items-center gap-x-3">
						<p class="user-name mb-0 text-journey-white">
							{{ `${userData.name}${userData.lastName ? " " + userData.lastName : ""}` }}
						</p>
						<img class="h-4" src="../../assets/icons/white-arrow.svg" alt="Seta para voltar" />
					</div>
				</template>

				<b-dropdown-item @click="goToProfile()" link-class="d-flex align-items-center">
					<feather-icon size="16" icon="UserIcon" class="mr-50" />
					<span>Perfil</span>
				</b-dropdown-item>

				<b-dropdown-divider />

				<b-dropdown-item
					v-if="$store.state.auth.activeUser.role === 'HEALTH_PROMOTER'"
					@click="goToChat()"
					link-class="d-flex align-items-center"
				>
					<feather-icon v-if="$route.name !== 'Chat'" size="16" icon="MessageSquareIcon" class="mr-50" />
					<span>{{ this.$route.name !== "Chat" ? "Chat" : "Portal de Promotores" }}</span>
				</b-dropdown-item>

				<b-dropdown-divider v-if="$store.state.auth.activeUser.role === 'HEALTH_PROMOTER'" />

				<b-dropdown-item
					v-if="$store.state.auth.activeUser.role === 'ADMINISTRATOR'"
					@click="goToPortal()"
					link-class="d-flex align-items-center"
				>
					<span v-if="$route.meta.kind !== 'withoutSidebarLayout'" class="font-bold">Portal de Promotores</span>
					<span v-else-if="$route.meta.kind === 'withoutSidebarLayout'" class="font-bold">Portal Administrativo</span>
				</b-dropdown-item>

				<b-dropdown-divider v-if="$store.state.auth.activeUser.role === 'ADMINISTRATOR'" />

				<b-dropdown-item @click="logout" link-class="d-flex align-items-center">
					<feather-icon size="16" icon="LogOutIcon" class="mr-50" />
					<span>Sair</span>
				</b-dropdown-item>
			</b-nav-item-dropdown>
		</b-navbar-nav>
	</div>
</template>

<script>
	import {
		BLink,
		BNavbarNav,
		BNavItemDropdown,
		BDropdownItem,
		BDropdownDivider,
		BAvatar,
		BNavbarBrand,
	} from "bootstrap-vue";

	import DarkToggler from "@core/layouts/components/app-navbar/components/DarkToggler.vue";
	import { UserRole } from "@/interfaces/user";

	export default {
		components: {
			BNavbarNav,
			BNavItemDropdown,
			BDropdownItem,
			BDropdownDivider,
			BNavbarBrand,
		},
		props: {
			toggleVerticalMenuActive: {
				type: Function,
				default: () => {},
			},
		},
		methods: {
			async logout() {
				this.$store.dispatch("app/showLoading");
				await this.$store.dispatch("auth/logout");
				this.$store.dispatch("app/hideLoading");

				// Redirect to login page
				this.$router.push({ name: "login" });
			},
			goToPortal() {
				if (
					this.$store.state.auth.activeUser.role === UserRole.ADMINISTRATOR &&
					this.$route.meta.kind !== "withoutSidebarLayout"
				) {
					this.$router.push({ name: "portalPromotores" });
				} else if (this.$route.meta.kind === "withoutSidebarLayout") {
					this.$router.push({ name: "dashboard" });
				}
			},
			goToProfile() {
				if (this.$store.state.auth.activeUser.role === UserRole.ADMINISTRATOR) {
					this.$router.push({ name: "usuarios", params: { id: this.$store.state.auth.activeUser?.id } });
				} else {
					this.$router.push({ name: "Perfil", params: { id: this.$store.state.auth.activeUser?.id } });
				}
			},
			goToChat() {
				if (this.$route.meta.kind === "withoutSidebarLayout") {
					if (this.$store.state.auth.activeUser.role === UserRole.HEALTH_PROMOTER && this.$route.name !== "Chat") {
						this.$router.push({ name: "Chat" });
					} else {
						this.$router.push({ name: "portalPromotores" });
					}
				}
			},
		},

		data() {
			return {
				userData: this.$store.state.auth.activeUser,
			};
		},
	};
</script>
